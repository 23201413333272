@keyframes info {
  0%, 60% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.info, button.copy, button.copy:after {
  cursor: pointer;
  display: inline-block;
}

button.copy, button.copy:after {
  all: unset;
  box-sizing: border-box;
  opacity: .8;
  border: 2px solid #aaa;
  width: 13px;
  height: 15px;
  font-size: .7rem;
  transition: opacity .3s;
  position: absolute;
}

button.copy {
  margin: 0 .7em;
}

button.copy:after {
  content: " ";
  background-color: #fff;
  top: 20%;
  left: 20%;
}

button.copy:hover {
  opacity: 1;
  border-color: #003049;
}

.info {
  vertical-align: top;
  text-align: center;
  color: #fff;
  width: 30px;
  height: 30px;
  margin: 0;
  transition: background-color .4s;
  position: relative;
}

.info:before {
  content: "i";
  text-transform: none;
  text-align: center;
  background-color: #0092c3;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-family: monospace;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  position: absolute;
  top: 8px;
  left: 6px;
}

.info:after {
  content: attr(data-text);
  visibility: hidden;
  z-index: 400;
  opacity: 0;
  background-color: #050505e6;
  width: 200px;
  max-width: 40vw;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  transition: opacity .4s;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)translateY(-100%);
  box-shadow: 0 .2em .5em #0006;
}

.info:hover:after {
  visibility: visible;
  opacity: 1;
}

.conteneur {
  cursor: default;
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0 20px;
  display: flex;
}

.element {
  border: 1px solid #d9dadb;
  border-radius: 2px;
  width: 360px;
  padding: 5px;
}

@media screen and (width <= 2560px) {
  .element {
    width: 280px;
  }
}

@media screen and (width <= 480px) {
  .element {
    width: 100%;
  }
}

.element:hover {
  background-color: #f0f3fa;
  transition: background-color .5s;
}

.pool_conteneur {
  cursor: default;
  flex-wrap: wrap;
  display: flex;
}

.pool_titre {
  padding-right: .25em;
}

.pool_id {
  word-break: break-word;
  transition: background-color .5s;
}

.pool_id:active {
  background-color: #26a69a;
  transition: background-color .1s;
}

table {
  width: 100%;
}

caption {
  text-align: left;
  font-weight: 700;
}

th {
  text-align: left;
  width: 9em;
  font-weight: 400;
}
/*# sourceMappingURL=index.82f86e90.css.map */
